@import "src/styles/colors";
@import "src/styles/fonts";

.textarea {
    label {
        display: block;
        font-size: $font-size-xxxs;
        font-weight: $font-weight-medium;
        margin-bottom: .25rem;
    }

    textarea {
        display: block;
        width: 100%;
        padding: 1rem;
        font-family: $font-family;
        font-size: $font-size-xxs;
        font-weight: $font-weight-medium ;
        border: 1px solid $black-20;
        border-radius: 4px;
        box-sizing: border-box;
        resize: none;
    }
}