@import "src/styles/colors";
@import "src/styles/fonts";

.quantityInput {

    .label {
        display: block;
        font-size: $font-size-xxxs;
        font-weight: $font-weight-medium;
        margin-bottom: .25rem;
    }

    .quantityInputWrapper {
        display: flex;

        .input {
            width: 100%;
            border: 1px solid $black-20;
            text-align: center;
            -webkit-appearance: none;

            &[type=number] {
                -moz-appearance: textfield;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }


        .increaseButton,
        .decreaseButton {
            padding: .75rem;
            border: 1px solid $black-30;
            background-color: $black-30;
            font-size: $font-size-xxxs;
            cursor: pointer;
        }

        .increaseButton {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .decreaseButton {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }
}