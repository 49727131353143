@import "/src/styles/breakpoints";
@import "/src/styles/colors";
@import "/src/styles/fonts";

.steps {
    margin-top: 3rem;
}

.stepHeader {
    padding-top: 48px;

    h1 {
        color: $black-90;
        margin-bottom: 10px;
    }

    p {
        color: $black-50;
    }

    &.noItemsEligible {
        margin-bottom: 3rem;

        p {
            color: $black-50;
            margin-bottom: .25rem;
        }
    }
}

.returnItems {
    border-radius: 4px;
    overflow: hidden;

    header {
        padding: 1rem 2.375rem;
        background: linear-gradient(106.64deg, var(--primary-darkest-color) 1.67%, var(--primary-darkest-color) 43.13%, var(--primary-darkest-color) 102.43%);

        @media screen and (max-width: $screen-l) {
            padding: 1rem;
        }

        h2 {
            color: $white;
            margin-bottom: 0;
        }

        p {
            margin-top: .5rem;
            color: $black-30;
        }

        &.returnStatusHeader {
            display: flex;
            justify-content: space-between;
        }
    }

    .elegible {
        margin-bottom: 1rem;
    }
}

.returnContent {
    padding: .75rem;
    background-color: $white;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid $white;

    .product {
        &:last-child {
            border-bottom: none;
        }
    }

    &.error {
        border: 1px solid $error;
    }
}

.additionalComments {
    margin-top: 1.5rem;
    padding: 1.5rem 2rem;
    background-color: $white;
    border-radius: 4px;

    @media screen and (max-width: $screen-l) {
        padding: 1.5rem;
    }

    label {
        font-size: $font-size-xs !important;
        margin-bottom: .5rem !important;
    }
}

.returnStatusTitle {
    margin-bottom: 0;
}

.stepButtons {
    padding: 1rem 0;

    @media screen and (max-width: $screen-l) {
        z-index: 1;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        background-color: $white;
        border-top: 1px solid $black-20;
        box-shadow: 0px -10px 15px 0px rgba($color: $black, $alpha: 0.1);
    }

    .stepButtonsWrapper {
        button {
            width: 100%;
            padding: 1rem 0;
            border-radius: 4px;
            font-size: $font-size-xs;
            font-weight: $font-weight-semibold;

            @media screen and (max-width: $screen-l) {
                font-size: $font-size-xxs;
            }
        }

        &.step2 {
            @media screen and (max-width: $screen-l) {
                display: flex;
                flex-direction: row-reverse;
                box-sizing: border-box;
                justify-content: space-between;

                .backButton {
                    display: inline-block;
                    max-width: 25%;
                    margin: 0 0.5rem 0 0;
                }
            }
        }

        .backButton {
            margin-top: 1rem;
        }
    }
}

.stepErrors {
    margin-top: 1rem;
}

.stepError {
    display: block;
    margin-top: .5rem;
    color: $error;
    font-size: $font-size-xxs;

    &:empty {
        margin-top: 0;
    }

    @media screen and (max-width: $screen-l) {
        font-size: $font-size-xxxs;
        font-weight: $font-weight-medium;
    }
}

.refundMethodWrapper {
    padding: 1.25rem;
    margin-top: 1.5rem;
    background-color: $white;
    border-radius: 4px;
}

.refundMethod {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
    padding: 1.5rem;
    background-color: var(--primary-darkest-color);
    box-sizing: border-box;
    border-radius: 4px;


    @media screen and (max-width: $screen-l) {
        display: block;
        background-color: $black-10;
        padding: 1rem 1.5rem;
        margin-top: 0;
    }

    .refundMethodLabel {
        color: $white;
        font-size: $font-size-xs;
        font-weight: $font-weight-semibold;
        white-space: nowrap;

        @media screen and (max-width: $screen-l) {
            color: $black;
            font-size: $font-size-xxxs;
        }
    }

    .refundMethodSelect {
        width: 100%;
        margin-left: 1rem;

        @media screen and (max-width: $screen-l) {
            margin-top: 0.25rem;
            margin-left: 0;
        }

        select {
            height: 30px;

            @media screen and (max-width: $screen-l) {
                height: 40px;
            }
        }
    }
}




.modal {
    width: 400px;
    max-width: 100%;
    padding: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    border: 2px solid $black-20;
    text-align: center;
    box-sizing: border-box;
    border-radius: 4px;

    &.modalCancel {
        width: auto;

        .buttonWrapper {
            display: flex;
            justify-content: space-between;

            div {
                width: 50%;
                padding: 0 .5rem;
            }

            button {
                font-size: $font-size-xs;
                font-weight: $font-weight-semibold;
            }
        }
    }

    .iconCheck {
        margin-bottom: 1.5rem;
        width: 40px;
        height: auto;
        color: $black-40;
    }

    h3 {
        margin-bottom: .5rem;
        color: $black-90;
        font-weight: $font-weight-bold;
    }

    p.subTitle {
        font-weight: $font-weight-medium;
        margin-bottom: 1.5rem;
        color: $black-90;
    }

    p.description {
        font-weight: $font-weight-medium;
        line-height: 1.2;
        margin-bottom: 1.5rem;
        color: $black-50;
    }

    .closeModalButton {
        width: 100%;
    }

    @media screen and (max-width: $screen-md) {
        padding: 1.5rem;
        max-width: 92%;
    }
}

.disclaimerText {
    padding: 1.5rem;
    margin-top: 1.5rem;
    background-color: $white;
    border-radius: 4px;

    p {
        padding: 1rem;
        font-weight: $font-weight-medium;
        background-color: $black-10;
        border-radius: 4px;

        span {
            font-weight: $font-weight-bold;
        }
    }
}

.returnSummaryWrapper {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $screen-md) {
        flex-direction: column-reverse;
    }
}

.stickyContent {
    height: fit-content;
}

.returnDate {
    color: $black-50;
    font-weight: $font-weight-medium;
}

.error {
    select {
        border: 1px solid rgba($color: $error, $alpha: .75) !important;
        transition: border-color 0.2s;
        animation: shake 0.5s ease-in-out;
    }
}

@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    20%,
    60% {
        transform: translateX(-5px);
    }


    40%,
    80% {
        transform: translateX(5px);
    }
}

.submitWrapper {
    width: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    @media screen and (max-width: $screen-l) {
        margin-left: 0.5rem;
    }

    button {
        max-width: 100%;
    }
}

.progressBar {
    height: 5px;
    padding: 0 4px;
    overflow: hidden;
    position: absolute;
    bottom: -1px;
    left: -7px;
    width: calc(100% + 10px);

    >span {
        background-color: var(--primary-lightest-color);

        span {
            background-color: var(--primary-light-color);
        }
    }
}

.returnDetailsAccordionHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;

    h2 {
        color: $white;
        font-size: $font-size-s;
        line-height: 28px;
        margin-bottom: 0;
    }

    .statusWrapper {
        display: flex;
        align-items: center;

        .toggleIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            margin-left: 1rem;
            border-radius: 50%;
            background-color: $white;
            color: var(--primary-darkest-color);
        }
    }
}

.rmaAccordionHeader {
    @media screen and (max-width: $screen-xs) {
        padding: 0 1rem !important;
    }
}

.rmaAccordionDetails {
    @media screen and (max-width: $screen-xs) {
        padding: .75rem !important;
    }
}

.backButton {
    width: 100%;
    padding: 1rem 0;
    border-radius: 4px;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
    margin-top: 1.5rem;

    @media screen and (max-width: $screen-l) {
        font-size: $font-size-xxs;
    }
}

.fixedSnackbar {
    position: initial !important;
    transform: none !important;
    margin-bottom: 1.5rem;

    @media screen and (max-width: $screen-xs) {
        margin-bottom: 1rem;
        font-size: $font-size-xxs;
    }

    .fixedSnackbarAlert {
        display: flex;
        align-items: center;
        padding: 0.75rem 1rem;
        font-size: 0.9rem;

        @media screen and (max-width: $screen-xs) {
            font-size: $font-size-xxxs;
        }

        div {
            padding: 0;
        }
    }
}

:export {
    screenL: $screen-l;
}