@import "/src/styles/breakpoints";
@import "/src/styles/colors";
@import "/src/styles/fonts";

.orderSummary {
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $black-20;

    @media screen and (max-width: $screen-l) {
        margin-top: 1rem;
    }

    header {
        padding: 1.5rem;
        border-bottom: 1px solid $black-20;

        h3 {
            margin-bottom: .5rem;
        }

        p {
            color: $black-50;
        }
    }



    .summaryContent {
        padding: 1.5rem;
    }

    .totalPrice {
        padding-top: 1rem;
        border-top: 1px solid $black-20;

        &Row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: .25rem;

            span {
                font-size: $font-size-xxxs;
                font-weight: $font-weight-semibold;

                &:first-child {
                    color: $black-50;
                }

                &:last-child {
                    color: $black-90;
                }
            }
        }
    }
}