@import "/src/styles/breakpoints";
@import "/src/styles/colors";
@import "/src/styles/fonts";

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: $black-10;
}