@import "src/styles/colors";
@import "src/styles/fonts";

.select {

    label {
        display: block;
        font-size: $font-size-xxxs;
        font-weight: $font-weight-medium;
        margin-bottom: .25rem;
    }


    .selectWrapper {
        position: relative;

        select {
            display: block;
            width: 100%;
            padding: 0 .75rem;
            background-color: $white;
            border: 1px solid $black-20;
            border-radius: 4px;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

        svg {
            position: absolute;
            right: .75rem;
            top: 50%;
            transform: translate(0, -50%);

            path {
                fill: $black-30;
            }
        }
    }


}