@import "/src/styles/breakpoints";
@import "/src/styles/colors";
@import "/src/styles/fonts";

.returnDetails {
    margin-top: 1.5rem;
    background-color: $white;
    border-radius: 4px;

    .divider {
        display: block;
        height: 1px;
        width: 100%;
        background-color: $black-20;
    }

    header {
        padding: 1rem 2.25rem;

        @media screen and (max-width: $screen-md) {
            padding: 1rem 1.125rem;
        }

        h3 {
            margin-bottom: .5rem;
        }

        p {
            color: $black-50;
            font-weight: $font-weight-medium;

            &:last-child {
                margin-top: .5rem;
            }
        }
    }

    .returnDetailsContent {
        display: flex;
        flex-direction: row;
        padding: 1rem 2.25rem;

        @media screen and (max-width: $screen-md) {
            flex-direction: column;
            padding: 1rem 1.125rem;
        }

        >div {
            &:first-child {
                width: 35%;
                padding-right: .75rem;

                @media screen and (max-width: $screen-l) {
                    width: 50%;
                }

                @media screen and (max-width: $screen-md) {
                    width: 100%;
                    padding-right: 0;
                }
            }

            &:last-child {
                width: 65%;
                padding-left: .75rem;

                @media screen and (max-width: $screen-l) {
                    width: 50%;
                }

                @media screen and (max-width: $screen-md) {
                    width: 100%;
                    padding-left: 0;
                }
            }
        }

        .returnDetailsPayment {
            max-width: fit-content;
            margin-left: auto;
            padding: 1rem;
            background-color: $black-10;
            border-radius: 4px;

            @media screen and (max-width: $screen-md) {
                max-width: 100%;
            }



            .creditCard {
                display: flex;
                align-items: center;
                margin-bottom: .75rem;
                font-size: $font-size-xxs;
                font-weight: $font-weight-semibold;
                color: $black-90;

                .creditCardIcon {
                    padding-top: 0.25rem;
                    font-size: $font-size-m;
                }
            }

            p {
                margin-bottom: .125rem;
                font-weight: $font-weight-semibold;
                color: $black-50;

                span {
                    color: $black-90;
                }
            }
        }
    }

}