@import "/src/styles/breakpoints";
@import "/src/styles/colors";
@import "/src/styles/fonts";

.accordion {
    &:before {
        height: 0 !important;
    }
}

.accordionSummaryHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: $font-size-xxxs;
}

.accordionSummaryContent {
    width: 100%;

    p {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}