@import "/src/styles/colors";
@import "/src/styles/fonts";
@import "/src/styles/breakpoints";

.row {
    display: flex;
    margin: 0 -0.75rem;
    flex-direction: row;

    @media (max-width: $screen-l) {
        flex-direction: column;
    }
}