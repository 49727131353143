@import "src/styles/colors";
@import "src/styles/fonts";
@import "src/styles/breakpoints";

.footerContainer {
  display: flex;
  flex-direction: column;
  padding: 48px 0;
  background-color: var(--primary-darker-color);
  margin-top: 48px;

  @media screen and (max-width: $screen-l) {
    padding-bottom: 86px;
  }
}

.principal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;

  img {
    height: 50px;
  }
}

.imgContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 35%;

  @media screen and (max-width: $screen-xl) {
    width: 100%;
  }

  img {
    max-height: 50px;
  }
}

.linksList {
  display: flex;
  justify-content: space-evenly;
  font-size: $font-size-s;
  align-items: flex-start;
  width: 65%;

  @media screen and (max-width: $screen-xl) {
    width: 100%;
  }

  .column {
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 16px;
    color: var(--text-color);
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  font-size: $font-size-s;
  border-top: 1px solid $white-light;
  align-items: center;
  padding-top: 16px;

  .column {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .link {
      padding: 16px 0px 16px 16px;
      color: $white;
    }
  }
}

.rights {
  font-size: $font-size-xxs !important;
  color: $white;
}

.bottomLink {
  font-size: $font-size-xs;
}

.sectionTitle {
  color: $white !important;
}

.slogan>h2 {
  line-height: $line-height-l;
  margin-top: 1rem;
}

@media screen and (max-width: $screen-xl) {
  .principal {
    flex-direction: column;
  }

  .imgContainer {
    align-items: center;
  }

  .linksList {
    flex-direction: column;
    align-items: center;

    .column {
      padding-left: 0;
      align-items: center;
    }
  }

  .imgContainer {
    margin-bottom: 40px;
    width: auto;
    text-align: center;
  }

  .footer {
    justify-content: center;

    .column {
      .link {
        padding: 0px 16px;
        font-size: $font-size-xxs;
        text-align: center;
      }
    }
  }
}

:export {
  screenXl: $screen-xl;
}