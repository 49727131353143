$font-family: 'Manrope', sans-serif !important;

$font-size-xxl: 2.25rem;
$font-size-xl: 1.75rem;
$font-size-l: 1.5rem;
$font-size-m: 1.25rem;
$font-size-s: 1.125rem;
$font-size-xs: 1rem;
$font-size-xxs: 0.875rem;
$font-size-xxxs: 0.75rem;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$line-height-xl: 2.375rem;
$line-height-l: 1.625rem;
$line-height-ml: 1.25rem;
$line-height-m: 1.125rem;
$line-height-s: 1rem;