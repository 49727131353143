@import "/src/styles/breakpoints";
@import "/src/styles/colors";
@import "/src/styles/fonts";

.customerData {
    padding-bottom: 1rem;

    h4 {
        margin-bottom: .5rem;
    }

    p {
        color: $black-50;
        font-weight: $font-weight-medium;
        margin-bottom: .125rem;
        word-wrap: break-word;
    }

}