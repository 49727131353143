@import "/src/styles/breakpoints";
@import "/src/styles/colors";
@import "/src/styles/fonts";

.modal {
    width: 400px;
    max-width: 100%;
    padding: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    border: 2px solid $black-20;
    text-align: center;
    box-sizing: border-box;
    border-radius: 4px;

    &:focus-visible {
        outline: none;
    }

    .iconCheck {
        margin-bottom: 1.5rem;
        width: 40px;
        height: auto;
        color: $black-40;
    }

    h3 {
        margin-bottom: .5rem;
        color: $black-90;
        font-weight: $font-weight-bold;
    }

    p.subTitle {
        font-weight: $font-weight-medium;
        margin-bottom: 1.5rem;
        color: $black-90;
    }

    p.description {
        font-weight: $font-weight-medium;
        line-height: 1.2;
        margin-bottom: 1.5rem;
        color: $black-50;
    }

    .closeModalButton {
        width: 100%;
    }

    @media screen and (max-width: $screen-md) {
        padding: 1.5rem;
        max-width: 92%;
    }

    &.returnInstructions {
        width: 450px;
        text-align: left;

        h3 {
            text-align: center;
            margin-bottom: 1.5rem;
        }

        p {
            color: $black-50;
            font-weight: $font-weight-medium;
        }

        h4 {
            margin-bottom: .5rem;
        }

        ul {
            padding-left: 1.25rem;
            margin-bottom: 1.5rem;
            list-style-type: none;

            li {
                position: relative;
                padding-bottom: 1rem;
                padding-right: 1.25rem;

                &::before {
                    content: "";
                    position: absolute;
                    left: -21px;
                    top: 0;
                    bottom: 0;
                    width: 1px;
                    border-left: 1px dashed $black-20;
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: -26px;
                    top: 5px;
                    transform: translateY(0%);
                    width: 10px;
                    height: 10px;
                    background: linear-gradient(156.37deg, $black-20 19.57%, #CBCFD7 85.87%);
                    border: 1.5px solid rgba(255, 255, 255, 0.3);
                    border-radius: 50%;
                }

                &:first-child::before {
                    top: 20px;
                    padding-left: 0;
                }

                &:last-child::before {
                    bottom: 95px;
                    padding-right: 0;
                }
            }
        }

        .iconTruck {
            display: block;
            width: 40px;
            height: auto;
            margin: 0 auto 1.5rem auto;
            color: $black-40;
            text-align: center;
        }
    }
}