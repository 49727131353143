@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/fonts";

.svgLine {
    position: absolute;
    top: 0;
    left: 0;

    .line {
        fill: var(--primary-darkest-color);
    }
}