@import "/src/styles/colors";
@import "/src/styles/fonts";

.subNavbar {

    .subNavbarNav {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
        list-style: none;

        >div {
            position: relative;
            width: calc(100% / 3);
            padding-bottom: 35px;
            font-size: $font-size-xxxs;
            text-align: center;
            pointer-events: none;



            &:after {
                content: "";
                position: absolute;
                top: 5px;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 6px;
                height: 6px;
                background-color: $black-20;
                border-radius: 50%;
                border: 3px solid $white;
                transition: ease all .5s;
            }

            &.firstStep {
                cursor: pointer;
            }

            &.active {
                pointer-events: initial;

                &:after {
                    background-color: $white;
                    border: 3px solid var(--primary-darker-color);
                    transition: ease all .5s;
                    transition-delay: .3s;
                }
            }

            span {
                position: absolute;
                top: 30px;
                left: 50%;
                transform: translate(-50%, -50%);
                white-space: nowrap;
                font-weight: $font-weight-semibold;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 25px;
            left: 0%;
            width: 100%;
            height: 2px;
            background-color: $black-20;
            z-index: -1;
        }

        .progressBar {
            position: absolute;
            top: 24px;
            left: 0%;
            height: 3px;
            background-color: var(--primary-darker-color);
            transition: ease all .5s;

            &.STEP {
                &1 {
                    width: calc((calc(100% / 3))/2);
                }

                &2 {
                    width: calc(100% / 2);
                }

                &3 {
                    width: 100%;
                }
            }
        }
    }
}