@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/fonts";

.principal {
  position: relative;
  padding: 1rem 0;
  border-bottom: 1px solid $black-20;
  background-color: $white;

  &.portalLookAndFeelBackground {
    background-color: var(--navbar-background);
  }

  img {
    display: block;
    width: auto;
    height: 25px;
  }
}

.link {
  margin-left: 32px;
  color: var(--navbar-link-color) !important;
}

.links {
  display: flex;
  align-items: center;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login {
  margin-right: 16px;
  font-size: $font-size-xs;
}

.button {
  margin: 0px;
  height: 30px;
}



@media screen and (max-width: $screen-xl) {
  .menuContainer {
    padding-top: 64px;
    background-color: var(--navbar-background);
    top: 64px;
    display: flex;
    right: 0;
    flex-direction: column;
    height: calc(100vh - 64px);
    width: 259px;
    border-left: 1px solid $black-20;
    justify-content: space-between;
  }

  .links {
    justify-content: space-between;
  }

  .link {
    margin: 0;
  }

  .sites {
    display: flex;
    flex-direction: column;
  }

  .site {
    display: flex;
    height: 56px;
    margin: 0px 16px;
    width: auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $black-20;
  }

  .auth {
    display: flex;
    padding: 44px 0px;
    justify-content: center;
  }

  .singup,
  .login {
    display: flex;
    width: 40%;
    justify-content: center;
    align-items: center;
    margin: 0px 16px;
  }

  .burger {
    background: none;
    border: none;
    cursor: pointer;
    font-size: $font-size-xs;
  }
}

:export {
  screenXl: $screen-xl;
}