@import "/src/styles/breakpoints";
@import "/src/styles/colors";
@import "/src/styles/fonts";

.statusTag {
    padding: .375rem .75rem;
    font-size: $font-size-xxxs;
    font-weight: $font-weight-semibold;
    border-radius: 48px;
}

.refunded,
.pendingRefund,
.pendingReceipt,
.partiallyReceived,
.pendingRefundPartReceived {
    background: $success;
    color: $black;
}

.pendingApproval {
    background: $warning;
    color: $black;
}

.pendingCreation {
    background: $warning;
    color: $black;
}

.cancelled,
.closed {
    background: #b2102f;
    color: $white;
}