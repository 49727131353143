@import "/src/styles/breakpoints";
@import "/src/styles/colors";
@import "/src/styles/fonts";

.product {
  padding: 1.5rem;
  border-bottom: 1px solid $black-20;
  transition: all ease 0.3s;
  background-color: $white;

  &:last-child {
    border-bottom: none;
  }

  &.checked {
    background-color: $black-10;
    transition: all ease 0.3s;
  }

  @media screen and (max-width: $screen-l) {
    padding: 1rem 0.5rem;
  }

  .productContainer {
    display: flex;
    flex-direction: row;
  }

  .returnProduct {
    margin-top: 1rem;
    padding: 1.5rem;
    background: $black-10;
    border-radius: 4px;

    &Row {
      margin-bottom: 1rem;
    }

    .returnSelect {
      @media screen and (max-width: $screen-l) {
        margin-top: 1rem;
      }
    }
  }
}

.productImg {
  width: 95px;
  height: 95px;
  border: 3px solid $black-20;
  background-color: $white;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  flex-shrink: 0;

  @media screen and (max-width: $screen-l) {
    width: 90px;
    height: 90px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.productContent {
  position: relative;
  width: fit-content;
  padding-left: 1rem;
  box-sizing: border-box;

  @media screen and (max-width: $screen-l) {
    display: flex;
    align-items: center;
    padding-left: 0.75rem;
    padding-right: 0.5rem;
  }

  .priceWrapper {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    .discount {
      padding: 0.2rem 0.7rem;
      margin-left: 0.5rem;
      font-size: $font-size-xxxs;
      background: var(--primary-darker-color);
      color: $white;
      border-radius: 15px;
    }
  }

  h3 {
    font-weight: $font-weight-semibold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    @media screen and (max-width: $screen-l) {
      font-size: $font-size-xxs;
      margin-bottom: 0;
      -webkit-line-clamp: 4;
      line-clamp: 4;
    }
  }
}

.productCheckboxWrapper {
  display: flex;
  align-items: center;
  margin-left: auto;

  .productCheckbox {
    color: var(--primary-dark-color) !important;
  }
}

.price {
  font-size: $font-size-xs;
  font-weight: $font-weight-semibold;
  margin-bottom: 0;
}

.originalPrice {
  text-decoration: line-through;
  font-size: $font-size-xxs;
  font-weight: $font-weight-semibold;
  padding-left: 0.5rem;
  margin-bottom: 0;
  color: $black-50;
}

.returnAlreadyExists {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin-top: 1.5rem;
  width: fit-content;
  text-align: center;
  background-color: $black-10;
  border-radius: 4px;

  &.checked {
    background-color: $white;
  }

  .icon {
    margin-right: 0.5rem;
    font-size: $font-size-xxs;
  }
}

.quantity {
  font-size: $font-size-xxxs;
  font-weight: $font-weight-medium;

  span {
    color: $black-50;
    font-weight: $font-weight-semibold;
  }
}

.itemReturnDetails {
  .returnDetailsAccordionHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
      font-size: $font-size-xxs;
      line-height: 1.5rem;
    }

    .toggleIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $black-10;
    }
  }

  .returnDetailsContent {
    width: 50%;
    margin: auto;
    padding: 1rem;
    background-color: $white;

    @media screen and (max-width: $screen-l) {
      width: 100%;
      background-color: $black-10;
      padding: 0;
    }

    >div {
      &:first-child {
        padding-bottom: 1rem;
        border-bottom: 1px solid $black-20;
      }

      &:last-child {
        padding-top: 1rem;
      }
    }
  }

  .summaryExpanded {
    .toggleIcon {
      background-color: $white;
    }
  }
}

.returnDetailsTable {
  .returnDetailsTableRow {
    margin-bottom: 0.25rem;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: $font-weight-medium;

      &:first-child {
        color: $black-50;
        white-space: nowrap;
      }

      &:last-child {
        color: $black-90;
      }
    }
  }

  .returnDetailsNotes {
    flex-direction: column;

    span {
      &:first-child {
        margin-bottom: 0.25rem;
      }
    }
  }
}

.stepError {
  display: block;
  margin-top: .5rem;
  color: $error;
  font-size: $font-size-xxs;

  &:empty {
    margin-top: 0;
  }

  @media screen and (max-width: $screen-l) {
    font-size: $font-size-xxxs;
    font-weight: $font-weight-medium;
  }
}

.error {
  select {
    border: 1px solid rgba($color: $error, $alpha: 0.75) !important;
    transition: border-color 0.2s;
    animation: shake 0.5s ease-in-out;
  }

  textarea {
    border: 1px solid rgba($color: $error, $alpha: 0.75) !important;
    transition: border-color 0.2s;
    animation: shake 0.5s ease-in-out;
  }
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  20%,
  60% {
    transform: translateX(-5px);
  }


  40%,
  80% {
    transform: translateX(5px);
  }
}

:export {
  screenL: $screen-l;
}

.lineCode {
  color: $black-50;
  font-size: $font-size-xxs;
}