@import "src/styles/colors";
@import "src/styles/fonts";
@import "src/styles/breakpoints";

.minorFooter {
  position: relative;
  background-color: var(--primary-darkest-color);
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rights {
  font-size: $font-size-xxs !important;
  color: $white;
}

.isLogin {
  background-color: $white;
  border-top: 1px solid $black-20;

  .rights {
    color: $gray-dark !important;
  }
}