@import "/src/styles/breakpoints";
@import "/src/styles/colors";
@import "/src/styles/fonts";

.printLabel {
    margin-top: 1.5rem;
    background-color: $white;
    border-radius: 4px;
    position: relative;
    min-height: 108px;

    .printLabelWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2.25rem;

        .dueDate {
            text-transform: capitalize;
        }

        @media screen and (max-width: $screen-md) {
            display: block;
            padding: 1rem;

            button {
                width: 100%;
                margin-top: 1rem;
            }
        }

        p {
            margin-bottom: .25rem;
            color: $black-50;
            font-weight: $font-weight-medium;
        }

        .returnInstructionsButton {
            color: $black-90;
            font-weight: $font-weight-bold;
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.pdfButton {
    min-width: 142px;
}

.progressBar {
    height: 5px;
    padding: 0 4px;
    overflow: hidden;
}

.skeletonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 2.25rem;

    @media screen and (max-width: $screen-md) {
        padding: 1rem;
        flex-direction: column;
    }

    .skeletonCol {
        margin: 0;
        width: 50%;

        @media screen and (max-width: $screen-md) {
            width: 100%;
        }

        .skeletonTitle {
            width: 155px;
            height: 24px;
            margin-bottom: 12px;
        }

        .skeletonParagraph {
            font-size: 0.75rem;
            max-width: 250px;
        }

        .skeletonButton {
            width: 142px;
            height: 40px;
            margin-left: auto;

            @media screen and (max-width: $screen-md) {
                width: 100%;
                margin: 1rem auto 0 auto;
            }
        }
    }
}