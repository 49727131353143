@import "/src/styles/breakpoints";
@import "/src/styles/colors";
@import "/src/styles/fonts";

.stepHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3rem;
    padding-bottom: 1rem;

    @media screen and (max-width: $screen-xs) {
        flex-direction: column;
        align-items: start;

        >div {
            width: 100%;
        }
    }

    h1 {
        color: $black-90;
        margin-bottom: 10px;
    }

    p {
        color: $black-50;
        font-size: $font-size-xxs;
    }

    &.noItemsEligible {
        margin-bottom: 3rem;

        p {
            color: $black-50;
            margin-bottom: .25rem;
        }
    }
}

.createdReturnsButton {
    border-radius: 4px;
    font-size: $font-size-xxs;
    font-weight: $font-weight-semibold;

    @media screen and (max-width: $screen-l) {
        font-size: $font-size-xxs;
        width: 100%;
        margin-top: 1rem;
        padding: .5rem;
    }
}


.generateNewReturnButton {
    border-radius: 4px;
    font-size: $font-size-xxs;
    font-weight: $font-weight-semibold;

    @media screen and (max-width: $screen-l) {
        width: 100%;
    }
}


.generateNewReturnWrapper {
    padding: 1rem 0;

    @media screen and (max-width: $screen-l) {
        z-index: 2;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        background-color: $white;
        border-top: 1px solid $black-20;
        box-shadow: 0px -10px 15px 0px rgba($color: $black, $alpha: 0.1);
    }
}

.viewInstructions {
    color: $black;
    font-weight: $font-weight-semibold;
    text-decoration: underline;
    cursor: pointer;
}

:export {
    screenMd: $screen-md;
}