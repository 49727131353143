@import "src/styles/fonts";
@import "src/styles/colors";

.iconContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: $black-20;
  z-index: 1;

  .icon {
    font-size: $font-size-m;
    z-index: 1;
  }
}

.svgLine {
  position: absolute;
  top: 0;
  left: 0;

  .line {
    fill: var(--primary-darkest-color);
  }
}

.deliveredIcon {
  width: 30px;
}

.errorText {
  color: $warning;
}