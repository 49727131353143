@import "src/styles/colors";
@import "src/styles/breakpoints";
@import "src/styles/fonts";

.returnShipment {
    position: relative;
    padding: 1rem 2.25rem;
    background-color: $white;
    border-radius: 4px;
    margin-top: 1.5rem;
    min-height: 84px;
    box-sizing: border-box;

    @media screen and (max-width: $screen-md) {
        padding: 1rem 1.125rem;
    }

    .returnShipmentWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            margin-bottom: .5rem;
        }

        p {
            color: $black-50;
            font-weight: $font-weight-medium;
        }
    }

    .returnShipmentStatus {
        display: flex;
        align-items: center;

        p {
            font-size: $font-size-xxs;
            font-weight: $font-weight-semibold;
            color: $black;
            padding-right: 1rem;
        }
    }

}

.trackingURL {
    font-size: $font-size-xxxs;
    font-weight: $font-weight-semibold;
    text-decoration: underline;
    color: $black;
}

.skeletonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .skeletonCol {
        margin: 0;
        width: 50%;

        .skeletonTitle {
            font-size: 1.125rem;
            width: 155px;
            height: 24px;
            margin-bottom: 12px;
        }

        .skeletonParagraph {
            font-size: 0.75rem;
            max-width: 79px;
        }

        .skeletonCircle {
            width: 48px;
            height: 48px;
            margin-left: 1rem;
        }

        .skeletonWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .skeletonParagraph {
                width: 50px;
            }
        }
    }
}