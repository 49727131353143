@import "src/styles/colors";
@import "/src/styles/fonts";

.link {
  text-decoration-line: none;
  font-weight: normal;

  &:hover,
  &:active {
    cursor: pointer;
  }
}

.primary {
  color: var(--primary-color);
  font-size: $font-size-s;
  line-height: $line-height-ml;

  &:hover {
    color: var(--primary-color);
    filter: brightness(95%);
  }

  &:active {
    color: var(--primary-color);
    filter: brightness(85%);
  }
}

.secondary {
  color: var(--text-color);
  font-size: $font-size-xs;
  line-height: $line-height-ml;
  filter: brightness(95%);

  &:hover {
    color: var(--text-color);
    filter: brightness(85%);
  }

  &:active {
    color: var(--text-color);
    filter: brightness(75%);
  }
}

.underlined {
  text-decoration-line: underline;
}

.bold {
  font-weight: bold;
}
